<template>
  <v-container>
    <v-layout row justify-center>
      <v-overlay v-model="loading" persistent fullscreen id="loading-dialog" content-class="loading-dialog">
        <v-container fill-height>
          <v-layout row justify-center align-center>
            <v-progress-circular indeterminate color="secondary"></v-progress-circular>
          </v-layout>
        </v-container>
      </v-overlay>
    </v-layout>
    <v-dialog v-model="confirmationDialog" width="800px">
      <v-card>
        <v-card-title>
          <span v-html="$t('completion_confirmation_dialog_title')" />
        </v-card-title>
        <v-card-text>
          <span v-html="$t('completion_confirmation_dialog_text')" />
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary shadow-none" class="button-margin" @click="finaliseAndCloseDialog"><span v-html="$t('complete')" /></v-btn>
          <v-btn color="secondary shadow-none" class="button-margin" @click="cancelDialog">
            <span v-html="$t('product_edit_product_cancel')" />
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <h3>
      <span v-html="$t('processing_form_edit_step_three_finalisation')" /> -
      <span v-html="$t('processing_form_edit_step_three_period')" />
      {{ $formatChDate(processingForm.validFrom) }} -
      {{ $formatChDate(processingForm.validUntil) }}
    </h3>
    <v-col cols="12"><h5 v-html="$t(generateCustomSubHeaderTextfield())"></h5></v-col>

    <v-form ref="completeForm" @submit.prevent="finalise()" id="completeForm">
      <v-row>
        <v-col sm="6" md="3" v-if="warningCount > 0" id="warningCount" :e2e_warningCount="warningCount">
          <span v-html="$t('processing_form_edit_step_tree_warning')" />
          <v-chip pill :color="colors['WARNING']" class="ma-2" variant="flat"> {{ warningCount }}</v-chip>
        </v-col>
        <v-col
          sm="6"
          md="9"
          v-if="
            warningCount > 0 &&
            ($privileges.has({
              path: '/processingForms/completion/controlling',
              permission: 'read'
            }) ||
              $privileges.has({
                path: '/processingForms/completion/admin',
                permission: 'read'
              }) ||
              $privileges.has({
                path: '/processingForms/completion/reporting',
                permission: 'read'
              }))
          "
        >
          <v-checkbox
            id="acceptWarnings"
            v-model="processingForm.acceptWarnings"
            :label="$t('processing_form_edit_step_tree_ignore_warnings_label')"
            name="acceptWarnings"
            :true-value="true"
            :false-value="false"
          />
        </v-col>

        <v-col sm="6" md="3" v-if="errorCount > 0" id="errorCount" :e2e_errorCount="errorCount">
          <span v-html="$t('processing_form_edit_step_tree_error')" />
          <v-chip pill :color="colors['ERROR']" class="ma-2" variant="flat"> {{ errorCount }}</v-chip>
        </v-col>
        <v-col
          sm="6"
          md="9"
          v-if="
            errorCount > 0 &&
            $privileges.has({
              path: '/processingForms',
              permission: 'acceptErrors'
            })
          "
        >
          <v-checkbox
            :disabled="
              !$privileges.has({
                path: '/processingForms/completion/admin',
                permission: 'write'
              })
            "
            id="acceptErrors"
            v-model="processingForm.acceptErrors"
            :label="$t('processing_form_edit_step_tree_ignore_errors_label')"
            name="acceptErrors"
            :true-value="true"
            :false-value="false"
          />
        </v-col>
      </v-row>
      <v-row v-if="warningCount > 0 || errorCount > 0">
        <v-col>
          <v-alert border="top" colored-border type="info" elevation="2">
            <span v-html="$t('processing_form_edit_step_tree_info')" />
          </v-alert>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <optionalOverlay :overlay="showRemarkOverlay.remark || loader.remark">
            <v-textarea
              v-if="
                $privileges.has({
                  path: '/processingForms/completion/controlling',
                  permission: 'read'
                }) ||
                $privileges.has({
                  path: '/processingForms/completion/admin',
                  permission: 'read'
                }) ||
                $privileges.has({
                  path: '/processingForms/completion/reporting',
                  permission: 'read'
                })
              "
              :disabled="
                $privileges.has({
                  path: '/processingForms/completion/controlling',
                  permission: 'write'
                })
              "
              filled
              rows="5"
              id="processing_form_remarks"
              :label="$t('processing_form_edit_step_tree_remarks_label_verwerter')"
              v-model="processingForm.remark"
              required
              @focus="showRemarkOverlay.remark = true"
              @blur="
                () => {
                  saveRemark('remark')
                  showRemarkOverlay.remark = false
                }
              "
              :loading="loader.remark"
            ></v-textarea>
          </optionalOverlay>
        </v-col>
      </v-row>

      <v-switch
        v-if="
          $privileges.has({
            path: '/processingForms/completion/forceSubsidyInvoice',
            permission: 'read'
          })
        "
        :disabled="
          !$privileges.has({
            path: '/processingForms/completion/forceSubsidyInvoice',
            permission: 'write'
          })
        "
        v-model="processingForm.forceSubsidyInvoice"
        :label="$t('processing_form_edit_step_tree_force_subsidy_invoice')"
      ></v-switch>

      <v-row>
        <v-col>
          <optionalOverlay :overlay="showRemarkOverlay.remarkAdmin || loader['remarkAdmin']">
            <v-textarea
              v-if="
                $privileges.has({
                  path: '/processingForms/completion/controlling',
                  permission: 'read'
                }) ||
                $privileges.has({
                  path: '/processingForms/completion/admin',
                  permission: 'write'
                })
              "
              :disabled="
                !$privileges.has({
                  path: '/processingForms/completion/admin',
                  permission: 'write'
                })
              "
              filled
              rows="5"
              id="processing_form_remarks_admin"
              :label="$t('processing_form_edit_step_tree_remarks_label_admin')"
              v-model="processingForm.remarkAdmin"
              required
              @focus="showRemarkOverlay.remarkAdmin = true"
              @blur="
                () => {
                  saveRemark('remarkAdmin')
                  showRemarkOverlay.remarkAdmin = false
                }
              "
              :loading="loader.remarkAdmin"
            ></v-textarea>
          </optionalOverlay>
        </v-col>
      </v-row>
      <v-row>
        <v-select
          v-if="checkIfWeShow()"
          :disabled="
            !(
              $privileges.has({
                path: '/processingForms/completion/controlling',
                permission: 'write'
              }) && processingForm.formStatusIdent == 50
            )
          "
          id="processing_form_accept_correction_amount"
          v-model="processingForm.acceptCorrectionAmount"
          :label="$t('processing_form_edit_step_three_correction_amount')"
          :items="correctionAmountItems"
          clearable
          @keyup.enter="search"
          :rules="calculateRulesForDropdown()"
        />
      </v-row>

      <v-row>
        <v-col>
          <optionalOverlay :overlay="showRemarkOverlay.remarkFederalOffice || loader.remarkFederalOffice">
            <v-textarea
              v-if="
                $privileges.has({
                  path: '/processingForms/completion/controlling',
                  permission: 'read'
                })
              "
              :disabled="
                !$privileges.has({
                  path: '/processingForms/completion/controlling',
                  permission: 'write'
                })
              "
              filled
              rows="5"
              id="processing_form_remarks_federal_office"
              :label="$t('processing_form_edit_step_tree_remarks_label_federal_office')"
              v-model="processingForm.remarkFederalOffice"
              :rules="calculateRules()"
              :loading="loader.remarkFederalOffice"
              @focus="showRemarkOverlay.remarkFederalOffice = true"
              @blur="
                () => {
                  saveRemark('remarkFederalOffice')
                  showRemarkOverlay.remarkFederalOffice = false
                }
              "
            ></v-textarea>
          </optionalOverlay>
        </v-col>
      </v-row>
      <v-btn
        id="finalise-with-confirmation"
        @click="openConfirmCompletionDialog"
        color="primary"
        v-if="showFinalise && needsConfirmation"
        :disabled="!enableFinalise || isPatchingARemarkInThisMoment"
        ><span v-html="$t('processing_form_edit_step_three_complete')"
      /></v-btn>
      <v-btn
        id="finalise"
        @click="finalise"
        color="primary"
        v-if="showFinalise && !needsConfirmation"
        :disabled="!enableFinalise || isPatchingARemarkInThisMoment"
        ><span v-html="$t('processing_form_edit_step_three_complete')"
      /></v-btn>
      <v-btn id="back" @click="goBack" color="secondary"><span v-html="$t('processing_form_edit_step_three_back')" /></v-btn>
      <v-btn id="cancel" @click="cancel" color="secondary"><span v-html="$t('processing_form_edit_step_three_cancel')" /></v-btn>
      <v-btn
        id="paper_report"
        :to="{ name: 'processingForms_edit_form_step_four', params: { id: this.$route.params.id } }"
        color="secondary"
        v-if="
          (processingForm.formStatusIdent >= 70 || processingForm.versionNo > 1) &&
          processingForm.dairyProcessingReportingObligation === 'YES_PAPER' &&
          $privileges.has({
            path: '/processingForms/reports/paper',
            permission: 'read'
          })
        "
        v-html="$t('step_three_print')"
      ></v-btn>
    </v-form>
  </v-container>
</template>

<script lang="ts">
import { privileges } from '@/services/privileges'
import { apiURL, emitter } from '@/main'
import { showError } from '@/services/axios'
import OptionalOverlay from '@/components/optionalOverlay.vue'
import { CustomFormatter } from '@/views/translations/CustomFormatter'

import { defineComponent } from 'vue'
export default defineComponent({
  data() {
    return {
      confirmationDialog: false,
      loader: { remarkFederalOffice: false, remarkAdmin: false, remark: false },
      showRemarkOverlay: {
        remarkFederalOffice: false,
        remarkAdmin: false,
        remark: false
      },
      correctionAmountItems: [
        {
          text: this.$t('processing_form_edit_step_three_correction_amount_yes'),
          value: true
        },
        {
          text: this.$t('processing_form_edit_step_three_correction_amount_no'),
          value: false
        }
      ],
      allowAcceptErrors: privileges.has({
        path: '/processingForms',
        permission: 'acceptErrors'
      }),
      processingForm: {
        id: 0,
        dairyProcessingReportingObligation: 'default',
        formStatus: {
          ident: 0
        }
      },
      processingFormValidationResult: {},
      colors: {
        OK: 'green',
        WARNING: 'yellow darken-3',
        ERROR: 'red'
      },
      icons: {
        OK: 'mdi-checkbox-marked-circle',
        WARNING: 'mdi-alert',
        ERROR: 'mdi-cancel'
      },
      loading: false,
      segmentationFormToSpringToId: undefined
    }
  },
  components: {
    OptionalOverlay: OptionalOverlay
  },
  computed: {
    warningCount: {
      get() {
        try {
          return this.processingFormValidationResult.filter((elX) => elX.processingFormValidationResult === 'WARNING')[0].validationCount
        } catch (e) {
          return 0
        }
      }
    },
    errorCount: {
      get() {
        try {
          return this.processingFormValidationResult.filter((elX) => elX.processingFormValidationResult === 'ERROR')[0].validationCount
        } catch (e) {
          return 0
        }
      }
    },
    showFinalise() {
      return (
        // Verwerter oder TSM für Verwerter
        (this.$privileges.has({
          path: '/processingForms/completion/reporting',
          permission: 'write'
        }) &&
          this.processingForm.formStatus.ident === 30) ||
        // BLW
        (this.$privileges.has({
          path: '/processingForms/completion/controlling',
          permission: 'write'
        }) &&
          this.processingForm.formStatus.ident === 50) ||
        // TSM
        (this.$privileges.has({
          path: '/processingForms/completion/admin',
          permission: 'write'
        }) &&
          this.processingForm.formStatus.ident === 40)
      )
    },
    needsConfirmation() {
      let today = new Date()
      let validUntil = new Date(this.processingForm.validUntil)
      if (today <= validUntil) {
        return true
      }
      return false
    },
    isPatchingARemarkInThisMoment() {
      if (this.loader.remarkFederalOffice == true || this.loader.remarkAdmin == true || this.loader.remark == true) {
        return true
      } else {
        return false
      }
    },

    enableFinalise() {
      if (
        this.forfeited() &&
        this.$privileges.has({
          path: '/processingForms/completion/admin',
          permission: 'write'
        }) &&
        !this.$privileges.has({
          path: '/processingForms/completion/forceSubsidyInvoice',
          permission: 'write'
        })
      )
        return false
      if (this.warningCount === 0 && this.errorCount === 0) return true
      if (this.allowAcceptErrors) {
        // tsm higher rights
        if (
          ((this.errorCount && this.processingForm.acceptErrors) || this.errorCount === 0) &&
          ((this.warningCount && this.processingForm.acceptWarnings) || this.warningCount === 0)
        ) {
          return true
        }
      } else {
        if (this.warningCount && this.processingForm.acceptWarnings && this.errorCount === 0) {
          return true
        }
      }
      return false
    }
  },
  methods: {
    async getProcessingForm() {
      try {
        const result = await this.axios.get(
          apiURL + '/processingForms/' + this.$route.params.id,
          {
            id: this.$route.params.id
          },
          { headers: { 'Content-Type': 'application/json' } }
        )
        if (await result) {
          return result.data
        }
      } catch (e) {
        showError(e)
      }
    },
    async saveRemark(remarkType) {
      try {
        this.loader[remarkType] = true
        const response = await this.axios.patch(
          `${apiURL}/processingForms/${this.$route.params.id}`,
          {
            [remarkType]: this.processingForm[remarkType]
          },
          { headers: { 'Content-Type': 'application/json' } }
        )
        if (await response) {
          emitter.emit('toastSuccess', this.$t('toast_save_success_message'))
        }
      } catch (e) {
        showError(e)
      } finally {
        this.loader[remarkType] = false
      }
    },
    openConfirmCompletionDialog() {
      this.confirmationDialog = true
    },
    finaliseAndCloseDialog() {
      this.finalise()
      this.confirmationDialog = false
    },
    cancelDialog() {
      this.confirmationDialog = false
    },
    async finalise() {
      if (!(await this.$refs.completeForm.validate()).valid) {
        return
      }
      if (
        this.$privileges.has({
          path: '/processingForms/completion/admin',
          permission: 'write'
        })
      ) {
        this.updateformStatus(70)
      } else if (
        this.$privileges.has({
          path: '/processingForms/completion/reporting',
          permission: 'write'
        })
      ) {
        this.updateformStatus(40)
      }
    },
    checkIfWeShow() {
      if (
        this.$privileges.has({
          path: '/processingForms/completion/controlling',
          permission: 'read'
        }) &&
        (this.processingForm.formStatusIdent == 50 || this.processingForm.acceptCorrectionAmount == true || this.processingForm.acceptCorrectionAmount == false)
      )
        return true
      else return false
    },
    forfeited() {
      let year = this.$moment(this.processingForm.validUntil).year()
      if (this.$moment(this.processingForm.validUntil).month() >= 10) year = year + 1
      return this.$moment().isAfter(this.$moment([year, 11, 16]))
    },
    calculateRules() {
      if (
        privileges.has({
          path: '/processingForms/completion/controlling',
          permission: 'write'
        }) &&
        this.processingForm.acceptCorrectionAmount == false
      )
        return [this.$rules.required]
      else return [true]
    },
    calculateRulesForDropdown() {
      if (
        !(
          privileges.has({
            path: '/processingForms/completion/controlling',
            permission: 'write'
          }) && this.processingForm.formStatusIdent == 50
        )
      )
        return [true]
      return [this.$rules.isNotNull]
    },
    async updateformStatus(nextStatus) {
      this.loading = true
      try {
        if (
          this.processingForm.formStatus.ident === 50 &&
          this.$privileges.has({
            path: '/processingForms/completion/controlling',
            permission: 'write'
          })
        ) {
          await this.updateCorrectionAmount()
        } else {
          await this.axios.patch(
            apiURL + '/processingForms/' + this.$route.params.id,
            {
              acceptErrors: this.processingForm.acceptErrors,
              acceptWarnings: this.processingForm.acceptWarnings,
              remark: this.processingForm.remark,
              remarkAdmin: this.processingForm.remarkAdmin,
              remarkFederalOffice: this.processingForm.remarkFederalOffice,
              forceSubsidyInvoice: this.processingForm.forceSubsidyInvoice,
              formStatus: { ident: nextStatus }
            },
            { headers: { 'Content-Type': 'application/json' } }
          )
        }
        emitter.emit('toastSuccess', {
          message: this.$t('toast_saved_message')
        })
        if (
          this.processingForm.dairyProcessingReportingObligation === 'YES_PAPER' &&
          privileges.has({
            path: '/processingForms/reports/paper',
            permission: 'read'
          })
        ) {
          this.$router
            .push({
              name: 'processingForms_edit_form_step_four',
              params: { id: this.$route.params.id }
            })
            .catch((e) => {
              /* duplicated route */
            })
        } else {
          const routeToSegmentation = await this.checkIfItRoutesToSegmentationForm()
          if (routeToSegmentation == true) {
            this.$router
              .push({
                name: 'segmentationForms_edit',
                params: { id: this.segmentationFormToSpringToId }
              })
              .catch((e) => {
                /* duplicated route */
              })
          } else {
            this.cancel()
          }
        }
      } catch (e) {
        showError(e)
      } finally {
        this.loading = false
      }
    },
    async checkIfItRoutesToSegmentationForm() {
      if (
        !this.$privileges.has({
          path: '/segmentationForms',
          permission: 'allDomains'
        })
      ) {
        try {
          const result = await this.axios.get(
            apiURL + '/segmentationForms/processingForm/' + this.$route.params.id,
            {
              id: this.$route.params.id
            },
            { headers: { 'Content-Type': 'application/json' } }
          )
          if (await result) {
            if (result.data.status == 'CREATED') {
              this.segmentationFormToSpringToId = result.data.id
            }
            return result.data.status == 'CREATED'
          }
        } catch (e) {
          return false
        }
      } else {
        return false
      }
    },
    async getProcessingFormValidationResult() {
      try {
        const result = await this.axios.get(
          apiURL + '/processingForms/' + this.$route.params.id + '/validationSummary',
          {
            params: {
              processingFormId: this.$route.params.id
            }
          },
          { headers: { 'Content-Type': 'application/json' } }
        )
        if (await result) {
          return result.data
        }
      } catch (e) {
        showError(e)
      }
    },
    async updateCorrectionAmount() {
      let nextStatus = 0
      if (this.processingForm.acceptCorrectionAmount) {
        nextStatus = 70
      } else {
        nextStatus = 40
      }
      try {
        await this.axios.patch(
          `${apiURL}/processingForms/${this.$route.params.id}/acceptCorrectionAmount`,
          {
            acceptCorrectionAmount: this.processingForm.acceptCorrectionAmount,
            remarkFederalOffice: this.processingForm.remarkFederalOffice,
            formStatus: { ident: nextStatus }
          },
          { headers: { 'Content-Type': 'application/json' } }
        )
      } catch (e) {
        showError(e)
      }
    },
    goBack() {
      this.$router
        .push({
          name: 'processingForms_edit_form_step_two',
          params: { id: this.$route.params.id }
        })
        .catch((e) => {
          /* duplicated route */
        })
    },
    cancel() {
      this.$router
        .push({
          name: 'processingForms_read'
        })
        .catch((e) => {
          /* duplicated route */
        })
    },
    generateCustomSubHeaderTextfield() {
      const mbhId = this.processingForm?.dairyIdent ? this.processingForm?.dairyIdent : ''
      const mbhName1 = this.processingForm?.dairyName1 ? this.processingForm?.dairyName1 : ''
      const mbhName2 = this.processingForm?.dairyName2 ? this.processingForm?.dairyName2 : ''
      const formatter = new CustomFormatter()
      return formatter.interpolate(this.$t('processing_form_edit_subheader_step_three'), [mbhId, mbhName1, mbhName2])
    }
  },
  mounted: function () {
    this.$nextTick(async () => {
      this.processingForm = await this.getProcessingForm()
      this.processingFormValidationResult = await this.getProcessingFormValidationResult()
    })
  }
})
</script>

<style></style>
