<template>
  <div>
    <v-overlay v-model="localOverlay"></v-overlay>
    <div :class="{ highlight: overlay }"><slot /></div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'optionalOverlay',
  props: {
    overlay: { type: Boolean, required: false, default: false }
  },
  data() {
    return {}
  },
  computed: {
    localOverlay: {
      get(): boolean {
        return this.overlay
      },
      set(val: boolean) {
        this.$emit('update', val)
      }
    }
  },
  methods: {}
})
</script>
<style lang="css">
.highlight {
  z-index: 2100;
  position: relative;
}
</style>
